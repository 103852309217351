import React from 'react';
import Logo from '../../assets/main/logo.svg';
import Position from '../../assets/icons/position.svg';
import Map from '../../assets/main/map.svg';
import ScrollBtn from '../ScrollBtn';
import './style.scss';

const Welcome = () => (
  <section className="welcome">
    <header>
      <img src={Logo} alt="On The Go Logo" />
      <div>
        <a href="https://goo.gl/maps/P7Dnakopye9faBC68" target="_new">
          <img src={Position} alt="Position icon" />
          Delivery Area
          <br></br>
          Orange County
        </a>
      </div>
    </header>
    <div className="welcome-container">
      <div className="text-container">
        <h1>Let's run together</h1>
        <ScrollBtn />
      </div>
      <div className="map-animation-container">
        <div className="animation-container" />
        <img src={Map} alt="map" />
      </div>
    </div>
  </section>
);

export default Welcome;
