import React from 'react';
import emailservice from '../../api/emailservice';
import './style.scss';

const validNameRegex = RegExp(
  /^[A-zÁ-ź .ñ']+$/,
);

const validEmailRegex = RegExp(
  /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/,
);

const validPhoneRegex = RegExp(
  /^[0-9( )\-+]+$/,
);

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
      error: null,
    };
    this.formBtn = React.createRef();
    this.thanks = React.createRef();
  }

  cleanForm = () => {
    this.setState({
      form: {
        name: { text: 'name', value: '', error: null },
        phone: { text: 'phone', value: '', error: null },
        email: { text: 'email', value: '', error: null },
      },
    });
  }

  checkErrorForm = (name, value) => {
    let error;

    switch (name) {
      case 'name':
        error = validNameRegex.test(value)
          ? null
          : 'Full name is not valid!';
        break;
      case 'phone':
        error = validPhoneRegex.test(value)
          ? null
          : 'Phone number is not valid!';
        break;
      case 'email':
        error = validEmailRegex.test(value)
          ? null
          : 'Email is not valid!';
        break;
      default:
        break;
    }

    this.setState(state => ({
      form: { ...state.form, [name]: { text: name, value, error } },
    }));

    return error ? 1 : 0;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.checkErrorForm(name, value);
  }

  checkFormErros = form => {
    let errors = 0;
    Object.values(form).forEach(e => {
      errors += this.checkErrorForm(e.text, e.value);
    });
    return errors;
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.formBtn.current.disabled = true;
    const { form } = this.state;
    const { name, phone, email } = form;
    const errors = await this.checkFormErros(form);
    if (errors === 0) {
      this.formBtn.current.innerHTML = '<span class="spinner"></span>';
      emailservice.post('api/sendMail', {
        to: 'careers@otgdeliveryjobs.com',
        company_name: 'On The Go',
        name: name.value,
        phone_number: phone.value,
        email: email.value,
        secret_key: process.env.REACT_APP_SERVICE_EMAIL_API,
      })
        .then(res => {
          if (res.data === 'Email sent') {
            this.cleanForm();
            this.thanks.current.className = 'thanks';
            this.formBtn.current.disabled = false;
            setTimeout(() => {
              this.thanks.current.className = 'none';
            }, 5000);
          } else {
            this.setState({ error: res.data });
          }
        })
        .catch(() => {
          this.setState({ error: 'Something went wrong!' });
        })
        .finally(() => this.formBtn.current.innerHTML = 'Apply');
    } else {
      this.formBtn.current.disabled = false;
    }
  }

  renderBtnContent = () => {
    return (
      <span className="spinner" />
    );
  }

  render() {
    const { form, error } = this.state;
    const { name, phone, email } = form;

    return (
      <section id="form" className="form">
        <form onSubmit={this.handleSubmit}>
          <h2>Let's run together</h2>
          <h3>You’ll have an amazing experience working with us!</h3>
          <div id="block1" />
          <div id="block2" />
          <div id="block3" />
          <div id="block4" />
          <input
            name="name"
            type="text"
            className={name.error ? 'bad-input' : ''}
            value={name.value}
            onChange={this.handleChange}
            placeholder="Full name:"
          />
          {name.error ? <span>{name.error}</span> : ''}
          <input
            name="phone"
            type="tel"
            className={phone.error ? 'bad-input' : ''}
            value={phone.value}
            onChange={this.handleChange}
            placeholder="Phone number:"
          />
          {phone.error ? <span>{phone.error}</span> : ''}
          <input
            name="email"
            type="email"
            className={email.error ? 'bad-input' : ''}
            value={email.value}
            onChange={this.handleChange}
            placeholder="Email:"
          />
          {email.error ? <span>{email.error}</span> : ''}
          {error ? <span>{error}</span> : ''}
          <h3 className="none" ref={this.thanks}>
            Thank you for sharing your information.
            We will be contacting you shortly.
          </h3>
          <button type="submit" className="loading" ref={this.formBtn}>
            Apply
          </button>
        </form>
        <div>
          <h3>Contact us!</h3>
          <h4>On The Go</h4>
          <a target="_new" href="https://goo.gl/maps/P7Dnakopye9faBC68">Delivery area, Orange County</a>
          <a href="mailto:careers@otgdeliveryjobs.com">careers@otgdeliveryjobs.com</a>
        </div>
      </section>
    );
  }
}

export default Form;
