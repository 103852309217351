import React from 'react';
import './style.scss';

const Footer = () => (
  <footer className="footer">
    <div>
      <h4>2020 On The Go</h4>
      <h4>
        Powered by <a href="https://cyberhelpsolutions.com/" target="_new">Cyber Help Solutions</a>
      </h4>
    </div>
  </footer>
);

export default Footer;
