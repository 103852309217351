import React from 'react';
import MiniCheck from '../../assets/icons/mini_check.svg';
import './style.scss';

const Format = ({ img, title, options }) => {
  const renderOptions = () => (
    options.map(option => (
      <li key={option.id}>
        <img src={MiniCheck} alt="check" />
        {option.text}
      </li>
    ))
  );

  return (
    <article>
      <img src={img} alt="icon" />
      <h3>{title}</h3>
      <ul>
        {renderOptions()}
      </ul>
    </article>
  );
}

export default Format;
