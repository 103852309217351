import React from 'react';
import Ban from '../../assets/main/car.svg';
import './style.scss';

const About = () => (
  <section className="about">
    <img src={Ban} alt="Ban" />
    <h2>About us!</h2>
    <p>
      We are a package delivery company
      working with Amazon to give the best service to our customers.
    </p>
  </section>
);

export default About;
