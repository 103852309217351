import React from 'react';
import Check from '../../assets/icons/check.svg';
import Qualifications from '../../assets/icons/qualifications.svg';
import Responsibilities from '../../assets/icons/responsibilities.svg';
import Format from './Format';
import './style.scss';

const Work = () => {
  const works = [
    {
      id: 1,
      img: Check,
      title: 'Great benefits are waiting for you!',
      options: [
        { id: 1, text: 'Lucrative hourly compensation' },
        { id: 2, text: 'Dental' },
        { id: 3, text: 'Health' },
        { id: 4, text: 'Vision' },
        { id: 5, text: 'Paid time off' },
      ]
    },
    {
      id: 2,
      img: Qualifications,
      title: 'Qualifications',
      options: [
        { id: 1, text: 'Must be 21+ years older' },
        { id: 2, text: 'Must have a valid driver’s license' },
        { id: 3, text: 'Experience in delivery driving preferred' },
        { id: 4, text: 'Ability to lift at least 50 lbs' },
        { id: 5, text: 'Authorized to work in the United States' },
      ]
    },
    {
      id: 3,
      img: Responsibilities,
      title: 'Responsibilities',
      options: [
        { id: 1, text: 'Deliver packages to residential and commercial locations' },
        { id: 2, text: 'Responsible for the transport and loading/unloading of packages' },
        { id: 3, text: 'Give the best possible customer experience' },
        { id: 4, text: 'Maintain professionalism and a service orientation with clients' },
      ]
    },
  ];

  const renderWorks = () => (
    works.map(work => (
      <Format
        key={work.id}
        img={work.img}
        title={work.title}
        options={work.options}
      />
    ))
  );

  return (
    <section className="work">
      <h2>Work with us!</h2>
      <div>
        {renderWorks()}
      </div>
    </section>
  );
}

export default Work;
