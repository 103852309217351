import React from 'react';
import Boxes from '../../assets/main/boxes.svg';
import ScrollBtn from '../ScrollBtn';
import './style.scss';

const Apply = () => (
  <section className="apply">
    <h2>Join our delivery drivers’ team!</h2>
    <ScrollBtn />
    <img src={Boxes} alt="boxes" />
  </section>
);

export default Apply;
