import React from 'react';
import Welcome from './welcome';
import About from './about';
import Apply from './apply';
import Work from './work';
import Form from './form';
import Footer from './footer';

const App = () => (
  <>
    <Welcome />
    <About />
    <Apply />
    <Work />
    <Form />
    <Footer />
  </>
);
export default App;
